export class ResultList {
    carrierResults: Result[] = [];
    errors: ErrorMessage[] = [];

}

export class ErrorMessage {
    service: string;
    errorType: string;
    errorCode: string;
    message: string;
    details: string;
    timestamp: string;
}

export class Result {
    carrierName: string;
    carrier: string;
    netCharge: number;
    serviceType: string;
    serviceDescription?: string;
    billingWeight?: number;
    pickupDate: Date;
    deliveryDate?: Date;
}
