import {ServiceOption} from '../model/constants';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {RatesService} from '../search/search.service';
import {AddressService} from '../services/address.service';
import {Subscription} from 'rxjs';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {SEARCH, SearchStore} from '../search/search.store';
import {ConfirmationService, MessageService} from 'primeng/api';
import {MessageStore} from '../message/message.store';
import {Address, AutocompleteSuggestions} from '../model/address.model';
import {Store} from '../results/generic.store';
import {ResultListsStore} from '../results/result.list.store';
import {SearchFormProvider} from './search.form.provider';
import {DateTime} from 'luxon';
import {CREATED} from "../ship/ship.store";

@Component({
    selector: 'logle-search-detail',
    templateUrl: './search.detail.component.html',
    styleUrls: ['./search.detail.component.scss']
})
export class SearchDetailComponent implements OnChanges, OnInit {

    loading = false;
    groupedCarrierTypes: string[] = ['Eigene Verpackung/ Own packaging'];
    items: any;
    @Input()
    result: any;
    @Input()
    reset: boolean;
    @Output()
    searchStarted = new EventEmitter<boolean>();
    @Output()
    initialized = new EventEmitter<boolean>();
    packageDetails: UntypedFormArray;
    senderAddresses: AutocompleteSuggestions[];
    pickupAddresses: AutocompleteSuggestions[];
    destinationAddresses: AutocompleteSuggestions[];
    serviceOptions: ServiceOption[];
    subscriptions: Subscription[] = [];
    controls: AbstractControl[];
    searchParams: any;
    searchFormProvider: SearchFormProvider = new SearchFormProvider(this.formbuilder)
    @Input()
    showPickup: boolean;
    @Output()
    showPickupChanged = new EventEmitter<boolean>();
    searchForm = this.searchFormProvider.createSearchForm();

    constructor(private formbuilder: UntypedFormBuilder,
                private ratesService: RatesService,
                private addressService: AddressService,
                private messageStore: MessageStore,
                private searchStore: SearchStore,
                private messageService: MessageService,
                private confirmationService: ConfirmationService,
                private resultStore: Store,
                private resultListStore: ResultListsStore
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.reset && changes.reset.currentValue) {
            this.searchForm = this.searchFormProvider.resetForm()
        }
    }

    ngOnInit() {
        this.subscriptions.push(this.ratesService.shipmentOptionsSubject.subscribe(options => this.serviceOptions = options));
        const packageDetails = this.searchForm.controls.packageDetails
        this.controls = packageDetails.controls;
        this.searchForm.controls.showPickUp.valueChanges.subscribe(value => {
            this.showPickup = value;
            if (!this.showPickup) {
                const shipper = this.searchForm.controls.shipper.value;
                this.searchForm.controls.shipFrom.patchValue(shipper)
            }
            if (this.showPickup) {
                this.searchForm.controls.shipFrom.reset();
            }
        });
        this.searchForm.get('showPickUp').valueChanges.subscribe(
            value => this.showPickupChanged.emit(value)
        )
        this.ratesService.getServiceOptions();
        this.resultListStore.hasChanged().subscribe({
            next: flag => {
                if (flag === true) {
                    this.searchStarted.emit(true);
                    const result = this.resultListStore.getData();
                    if (result.errors.length === 0) {
                        this.messageStore.dispatch({
                            severity: 'success',
                            summary: 'Preissuche',
                            detail: 'Preissuche erfolgreich.'
                        })
                    } else {
                        result.errors.forEach(error => {
                            if (error.errorType === 'BUSINESS') {
                                this.messageStore.dispatch({
                                    severity: 'warn',
                                    summary: 'Preissuche',
                                    detail: error.service + ' - ' + error.message,
                                    sticky: true
                                })
                            } else {
                                if (error.errorType === 'TECHNICAL') {
                                    this.messageStore.dispatch({
                                        severity: 'error',
                                        summary: 'Preissuche',
                                        detail: error.service + ' - ' + error.message,
                                        sticky: true
                                    })
                                }
                            }
                        })
                    }


                } else {
                    this.messageStore.dispatch({
                        severity: 'info',
                        summary: 'Preissuche',
                        detail: 'Es konnten keine Preise für Ihre Kriterien gefunden werden. '
                    })
                    this.searchStarted.emit(false);
                }
            },
            error: flag => {
                this.searchStarted.emit(false)
                this.messageStore.dispatch({
                    severity: 'error',
                    summary: 'Preissuche',
                    detail: 'Es ist ein Fehler aufgetreten. Bitte informieren Sie den Support.'
                })
            },
            complete: () => {
                this.loading = false
            }
        })

        this.searchForm.controls.packageDetails.valueChanges.subscribe(() => this.initialized.emit(true));
    }


    fillSelectablePackages(): UntypedFormArray {
        return this.formbuilder.array([
            {
                label: '', value: '',
                items: [
                    {label: 'Eigene Verpackung', value: 'Eigene Verpackung/ Own packaging'}
                ]
            },
            {
                label: 'FedEx', value: 'fedex',
                items: [
                    {label: 'FedEx 10 kg Box', value: 'fedex:FEDEX_10KG_BOX'},
                    {label: 'FedEx 25 kg Box', value: 'fedex:FEDEX_25KG_BOX'},
                    {label: 'FedEx Box', value: 'fedex:FEDEX_BOX'},
                    {label: 'FedEx Envelope', value: 'fedex:FEDEX_ENVELOPE'},
                    {label: 'FedEx Extra Large Box', value: 'fedex:FEDEX_EXTRA_LARGE_BOX'},
                    {label: 'FedEx Large Box', value: 'fedex:FEDEX_LARGE_BOX'},
                    {label: 'FedEx Medium Box', value: 'fedex:FEDEX_MEDIUM_BOX'},
                    {label: 'FedEx Pak', value: 'fedex:FEDEX_PAK'},
                    {label: 'FedEx Small Box', value: 'fedex:FEDEX_SMALL_BOX'},
                    {label: 'FedEx Tube', value: 'fedex:FEDEX_TUBE'}
                ]
            },
            {
                label: 'UPS', value: 'ups',
                items: [
                    {label: 'UPS Letter', value: 'ups:UPS Letter:01'},
                    {label: 'UPS Tube', value: 'ups:Tube:03'},
                    {label: 'UPS PAK', value: 'ups:PAK:04'},
                    {label: 'UPS Express Box', value: 'ups:UPS Express Box:21'},
                    {label: 'UPS 25KG Box', value: 'ups:UPS 25KG Box:24'},
                    {label: 'UPS 10KG Box', value: 'ups:UPS 10KG Box:25'},
                    {label: 'UPS Pallet', value: 'ups:Pallet:30'},
                    {label: 'UPS Small Express Box', value: 'ups:Small Express Box:2a'},
                    {label: 'UPS Medium Express Box', value: 'ups:Medium Express Box:2b'},
                    {label: 'UPS Large Express Box', value: 'ups:Large Express Box:2c'},
                    {label: 'UPS Flats', value: 'ups:Flats:56'},
                    {label: 'UPS Parcels', value: 'ups:Parcels:57'},
                    {label: 'UPS BPM', value: 'ups:BPM:58'},
                    {label: 'UPS First Class', value: 'ups:First Class:59'},
                    {label: 'UPS Priority', value: 'ups:Priority:60'},
                    {label: 'UPS Machineables', value: 'ups:Machineables:61'},
                    {label: 'UPS Irregulars', value: 'ups:Irregulars:62'},
                    {label: 'UPS Parcel Post', value: 'ups:Parcel Post:63'},
                    {label: 'UPS BPM Parcel', value: 'ups:BPM Parcel:64'},
                    {label: 'UPS Media Mail', value: 'ups:Media Mail:65'},
                    {label: 'UPS BPM Flat', value: 'ups:BPM Flat:66'},
                    {label: 'UPS Standard Flat', value: 'ups:Standard Flat:67'}
                ]
            },
            {
                label: 'TNT', value: 'tnt',
                items: [
                    {label: 'Dokument', value: 'tnt:Dokument'},
                    {label: 'Box/Palette', value: 'tnt:Box/Palette'}
                ]
            }
        ]);
    }


    update() {
        setTimeout(() => {
            // console.log(this.searchForm.value)
            this.searchStore.dispatch({action: SEARCH, data: this.searchForm.getRawValue()});
        })
    }

    addPackage() {
        this.packageDetails = this.searchForm.get('packageDetails') as UntypedFormArray;
        this.packageDetails.push(this.searchFormProvider.createPackage());
        this.controls = (this.searchForm.get('packageDetails') as UntypedFormArray).controls;
    }

    removePackage(index: number) {
        // console.log("removePackage")
        this.packageDetails = this.searchForm.get('packageDetails') as UntypedFormArray;
        if (this.packageDetails.length > 1) {
            this.packageDetails.removeAt(index);
        }
    }

    apply() {
        const searchObject = this.searchForm.value;
        this.searchParams = this.searchForm.value;
        this.loading = true;
        this.messageService.clear();

        //Hier passirrt der Rate Backend Aufruf im Store
        this.resultListStore.setRateSearchContext(searchObject);
        this.searchStore.dispatch({data: searchObject, type: CREATED})
        this.searchStarted.emit(true);
    }

    search(event: any, type: string) {
        this.addressService.autocomplete(event).subscribe(value => {
            if (type === 'shipper') {
                this.senderAddresses = [...value];
            }
            if (type === 'shipTo') {
                this.destinationAddresses = [...value];

            }
            if (type === 'shipFrom') {
                this.pickupAddresses = [...value];
            }
        })
    }

    blub() {
        console.log('Form: ', this.searchForm);
    }

    selection(value: any, type: string) {
        console.log('selection with placeId', value.value.placeId);
        this.addressService.getPlace(value.value.placeId).subscribe(address => {
                if (type === 'shipper' && !this.showPickup) {
                    this.searchForm.controls.shipper.patchValue(address);
//                    this.searchForm.controls.pickUp.patchValue(address);
                } else if (type === 'shipTo') {
                    this.searchForm.controls.shipTo.patchValue(address);

                } else if (type === 'shipFrom') {
                    this.searchForm.controls.shipFrom.patchValue(address);
                } else if (type === 'shipper' && this.showPickup) {
                    this.searchForm.controls.shipper.patchValue(address);
                }
            }
        )
    }

    updateAddress(addressForm: UntypedFormGroup, address: Address) {
        addressForm.patchValue(address);
    }

    openDialog() {
        if (this.searchForm.dirty) {
            this.confirmationService.confirm({
                message: 'Wollen Sie die Eingaben zurücksetzen?',
                header: 'Suche zurücksetzen',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.searchForm = this.searchFormProvider.createSearchForm();
                },
                reject: () => {
                }
            });
        } else this.searchForm = this.searchFormProvider.createSearchForm();
    }

    mapShipperToPickupOnFocusout() {
        const shipper = this.searchForm.controls.shipper.value;
        this.searchForm.controls.shipFrom.patchValue(shipper);
    }

    mock() {
        const mockedValue = {
            showPickUp: false,
            shipFrom: {
                name: 'Cloud-Surfers GmbH',
                street: 'Joseph-von-Fraunhofer-Straße',
                streetNumber: '20',
                postalCode: '44227',
                city: 'Dortmund',
                countryCode: 'DE',
                cityState: '',
                personName: '',
                phoneNumber: '',
                emailAddress: ''
            },
            shipper: {
                name: 'Cloud-Surfers GmbH',
                street: 'Joseph-von-Fraunhofer-Straße',
                streetNumber: '20',
                postalCode: '44227',
                city: 'Dortmund',
                countryCode: 'DE',
                cityState: '',
                personName: '',
                phoneNumber: '',
                emailAddress: ''
            },
            shipTo: {
                name: 'CCL GmbH',
                street: 'Rechtenbacher Hohl',
                streetNumber: '1',
                postalCode: '35398',
                city: 'Gießen',
                countryCode: 'DE',
                cityState: '',
                personName: '',
                phoneNumber: '',
                emailAddress: ''
            },
            packageDetails: [{
                weight: 1,
                type: 'Eigene Verpackung/ Own packaging',
                dimension: {height: 10, length: 10, width: 10}
            }],
            deliveryDate: DateTime.fromISO('2024-07-16T10:00:42.310Z').toJSDate(),
            latestDeliveryDate: DateTime.fromISO('2024-07-17T10:00:42.311Z').toJSDate(),
            pickUpDate: DateTime.fromISO('2024-07-15T10:00:42.312Z').toJSDate(),
            latestPickupDate: DateTime.fromISO('2024-07-16T10:00:42.312Z').toJSDate()
        }
        this.searchForm.patchValue(mockedValue);
    }
}
