<form (ngSubmit)="apply()" [formGroup]="searchForm">


    <!-- ****************************************   ABSENDER PANEL   ******************************************* -->

    <p-panel class="p-m-1 full-width" header="Absender">

        <div class="p-grid p-mt-2" formGroupName="shipper">

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <p-autoComplete (completeMethod)="search($event, 'shipper')"
                                    (focusout)="mapShipperToPickupOnFocusout()"
                                    (onSelect)="selection($event, 'shipper')" [suggestions]="senderAddresses"
                                    appendTo="body"
                                    autofocus formControlName="name" id="firma"
                                    optionLabel="firma">
                        <ng-template let-autocompleteSuggestions pTemplate="item">
                            <p>{{ autocompleteSuggestions.formattedAddress }}</p>
                        </ng-template>
                    </p-autoComplete>
                    <label for="firma">Firma</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input (focusout)="mapShipperToPickupOnFocusout()" formControlName="street" id="street" pInputText
                           type="text"/>
                    <label for="street">Straße</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input (focusout)="mapShipperToPickupOnFocusout()" formControlName="streetNumber" id="housenumber"
                           pInputText
                           type="text">
                    <label for="housenumber">Hausnummer</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input (focusout)="mapShipperToPickupOnFocusout()" formControlName="countryCode" id="country"
                           maxlength="2" pInputText
                           type="text">
                    <label for="country">Land</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input (focusout)="mapShipperToPickupOnFocusout()" formControlName="postalCode" id="postalcode"
                           pInputText
                           type="text">
                    <label for="postalcode">PLZ</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input (focusout)="mapShipperToPickupOnFocusout()" formControlName="city" id="city" pInputText
                           type="text">
                    <label for="city">Stadt</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input (focusout)="mapShipperToPickupOnFocusout()" formControlName="cityState" id="state" pInputText
                           type="text">
                    <label for="state">Region</label>
                </span>
            </div>
        </div>

        <div class="p-col-12 p-p-0 ">
            <p-checkbox binary="true" class="extended_margin" formControlName="showPickUp"
                        label="Querversand"></p-checkbox>
        </div>
    </p-panel>

    <!-- ****************************************   ABHOLUNG-VON PANEL - FALLS QUERVERSAND AUSGEWÄHLT   ******************************************* -->

    <p-panel *ngIf="showPickup" class="p-m-1 full-width" class="extended_margin" header="Abholung von">

        <div class="p-grid p-mt-2" formGroupName="shipFrom">

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <p-autoComplete (completeMethod)="search($event, 'shipFrom')"
                                    (onSelect)="selection($event, 'shipFrom')" [suggestions]="pickupAddresses"
                                    appendTo="body"
                                    formControlName="name"
                                    id="puFirma">
                        <ng-template let-autocompleteSuggestions pTemplate="item">
                            <p>{{ autocompleteSuggestions.formattedAddress }}</p>
                        </ng-template>
                    </p-autoComplete>
                    <label for="puFirma">Firma</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input formControlName="street" id="puStreet" pInputText type="text">
                    <label for="puStreet">Straße</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input formControlName="streetNumber" id="puHousenumber" pInputText type="text">
                    <label for="puHousenumber">Hausnummer</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input formControlName="countryCode" id="puCountry" maxlength="2" pInputText type="text">
                    <label for="puCountry">Land</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input formControlName="postalCode" id="puPostalcode" pInputText type="text">
                    <label for="puPostalcode">PLZ</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input formControlName="city" id="puCity" pInputText type="text">
                    <label for="puCity">Stadt</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input formControlName="cityState" id="puState" pInputText type="text">
                    <label for="puState">Region</label>
                </span>
            </div>

        </div>
    </p-panel>

    <!-- ****************************************   ZUSTELLUNG-AN PANEL  ******************************************* -->

    <p-panel class="extended_margin" class="p-m-1 full-width" header="Zustellung an">
        <div class="p-grid p-mt-2" formGroupName="shipTo">
            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <p-autoComplete (completeMethod)="search($event, 'shipTo')" (onSelect)="selection($event,'shipTo')"
                                    [suggestions]="destinationAddresses"
                                    appendTo="body"
                                    formControlName="name"
                                    id="destFirma">
                        <ng-template let-autocompleteSuggestions pTemplate="item">
                            <p>{{ autocompleteSuggestions.formattedAddress }}</p>
                        </ng-template>
                    </p-autoComplete>
                    <label for="destFirma">Firma</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input formControlName="street" id="destStreet" pInputText type="text">
                    <label for="destStreet">Straße</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input formControlName="streetNumber" id="destHousenumber" pInputText type="text">
                    <label for="destHousenumber">Hausnummer</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input formControlName="countryCode" id="destCountry" maxlength="2" pInputText type="text">
                    <label for="destCountry">Land</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input formControlName="postalCode" id="destPostalcode" pInputText type="text">
                    <label for="destPostalcode">PLZ</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input formControlName="city" id="destCity" pInputText type="text">
                    <label for="destCity">Stadt</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <input formControlName="cityState" id="destState" pInputText type="text">
                    <label for="destState">Region</label>
                </span>
            </div>
        </div>
    </p-panel>


    <!-- ****************************************   ZEITEN PANEL   ******************************************* -->

    <p-panel class="p-m-1 full-width" header="Zustell-/Abholzeiten">
        <div class="p-grid p-mt-2 p-jc-start p-col-7">

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <p-calendar appendTo="body" dateFormat="dd'.'mm'.'yy'" formControlName="pickUpDate" id="pickupDate"
                                showTime="true"></p-calendar>
                    <label for="pickupDate">Früheste Abholung</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <p-calendar appendTo="body" dateFormat="dd'.'mm'.'yy'" formControlName="latestPickupDate"
                                id="latestPickupDate"
                                showTime="true"></p-calendar>
                    <label for="latestPickupDate">Späteste Abholung</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <p-calendar appendTo="body" dateFormat="dd'.'mm'.'yy'" formControlName="deliveryDate"
                                id="deliveryDate"
                                showTime="true"></p-calendar>
                    <label for="deliveryDate">Zustellzeit</label>
                </span>
            </div>

            <div class="p-col">
                <span class="p-float-label extended_margin">
                    <p-calendar appendTo="body" dateFormat="dd'.'mm'.'yy'" formControlName="latestDeliveryDate"
                                id="latestDeliveryDate"
                                showTime="true"></p-calendar>
                    <label for="latestDeliveryDate">Spät. Zustellzeit</label>
                </span>
            </div>
        </div>
    </p-panel>

    <!-- ****************************************   PAKET PANEL   ******************************************* -->

    <p-panel class="extended_margin full-width p-m-1" header="Pakete">
        <div class="p-mt-2">

            <div *ngFor="let item of controls; let i = index; let lst = last" formArrayName="packageDetails">
                <div [formGroupName]="i">

                    <div class="p-grid">
                        <div class="p-col extended_margin" formGroupName="dimension">
                            <span class="p-float-label extended_margin">
                                <input formControlName="length" id="packetLength" pInputText type="number">
                                <label for="packetLength">Länge</label>
                            </span>
                        </div>

                        <div class="p-col" formGroupName="dimension">
                            <span class="p-float-label extended_margin">
                                <input formControlName="width" id="packetWidth" pInputText type="number">
                                <label for="packetWidth">Breite</label>
                            </span>
                        </div>

                        <div class="p-col" formGroupName="dimension">
                            <span class="p-float-label extended_margin">
                                <input formControlName="height" id="packetHeight" pInputText type="number">
                                <label for="packetHeight">Höhe</label>
                            </span>
                        </div>

                        <div class="p-col">
                             <span class="p-float-label extended_margin">
                                 <input formControlName="weight" id="weight" pInputText type="number">
                                 <label for="weight">Gewicht</label>
                             </span>
                        </div>

                        <!--
                        <div class="p-col">
                            <p-multiSelect formControlName="additionalTypes" [options]="searchForm.controls['packageDetails'].value[i].groupedTypes" [group]="true" defaultLabel="Verpackungsart" scrollHeight="250px" display="chip"
                                           (click)="onSelect(searchForm.controls['packageDetails'], i)">
                                <ng-template let-group pTemplate="group">
                                    <div class="p-d-flex p-ai-center" pTooltip="Pro Kurier nur 1 Auswahl möglich">
                                        <img [src]="findCarrierPicture(group.value)"
                                             [class]="'p-mr-2 flag flag-' + group.value" style="width: 50px"/>
                                        <span style="font-weight: bold">{{group.label}}</span>
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </div>
                        -->
                        <div class="p-col">
                            <p-dropdown (onClick)="blub()" [options]="groupedCarrierTypes" appendTo="body"
                                        formControlName="type" placeholder="Verpackungsart" scrollHeight="250px">
                            </p-dropdown>
                        </div>

                        <div class="p-col">
                            <button (click)="addPackage()" *ngIf="lst" class="p-button-rounded p-mr-1" icon="pi pi-plus"
                                    pButton
                                    pRipple></button>
                            <button (click)="removePackage(i)" class="p-button-rounded p-button-danger"
                                    icon="pi pi-minus" pButton
                                    pRipple></button>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </p-panel>

    <!-- ****************************************   WEITERE SERVICEOPTION PANEL   ******************************************* -->

    <p-panel *ngIf="serviceOptions" [collapsed]="true" [toggleable]="true"
             class="extended_margin full-width" header="Weitere Service-Optionen">
        <div class="p-grid p-mt-2">
            <div *ngFor="let serviceOption of serviceOptions" class="p-col-3">
                <p-checkbox #key [formControl]="searchForm.controls['additionalServices']" [inputId]="serviceOption.key"
                            [label]="serviceOption.humanReadable"
                            [value]="serviceOption"
                            class="p-col-6" name="group1"></p-checkbox>
                <input *ngIf="serviceOption.valueRequired && key.checked " [placeholder]="serviceOption.value" autofocus
                       class="p-col-6"
                       pInputText pTooltip="{{serviceOption.description}}" tabindex="0">
                <div *ngFor="let connector of serviceOption.connectorOptions"
                     class="p-col-1">{{ connector.connector }}
                </div>
            </div>
        </div>
    </p-panel>

    <!-- ****************************************   BUTTONS   ******************************************* -->
    <p-footer>
        <table style="margin-left: auto;">
            <tr>
                <td>
                    <button (click)="openDialog()" class="p-button-danger p-mr-2" icon="pi pi-trash" iconPos="right"
                            label="Löschen"
                            pButton type="button"></button>
                    <button [disabled]="!searchForm.valid" icon="pi pi-envelope" iconPos="right" label="Anfragen"
                            pButton></button>
                    <button (click)="mock()" icon="pi pi-envelope" iconPos="right" label="Mock" pButton
                            type="button"></button>
                </td>
                <td>
                </td>
            </tr>
        </table>
    </p-footer>

    <p-progressBar *ngIf="loading" [style]="{'height': '6px'}" mode="indeterminate"></p-progressBar>
</form>
