import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {environment} from '../../environments/environment';
import {CLEAR, Store} from '../results/generic.store';
import {Result, ResultList} from '../results/result.model';
import {SEARCH, SearchStore} from './search.store';
import {GET, ServiceOptionStore} from './service.option.store';
import {ServiceOption} from '../model/constants';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'/*,    "Access-Control-Allow-Origin": "*"*/
    })
};

/**
 * The domain of this service is to retrieve search job entries and to create an orderDocuments.
 */
@Injectable({
    providedIn: 'root'
})
export class RatesService {
    subject: BehaviorSubject<ResultList>;
    shipmentOptionsSubject: BehaviorSubject<ServiceOption[]>
    subscription: Subscription;
    private searchUrl = environment.shippingService.searchUri;
    private configurationUri = environment.configurationService.serviceOptions;
// todo: why an array??
    private searchJobId: BehaviorSubject<string> = new BehaviorSubject('');
    /**
     * contains current search window.interval
     */
    private lastSearchIntervalId = 0;
    /**
     * provides information if a search window.interval is executing.
     */
    private isSearchingState: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private http: HttpClient, private resultStore: Store, private searchStore: SearchStore,
                private shipmentOptionStore: ServiceOptionStore) {
        this.subject = this.resultStore.item;
        this.shipmentOptionsSubject = shipmentOptionStore.item;
    }

    search(searchParams: any) {
        this.resultStore.dispatch({data: [], type: CLEAR});

        let res = this.http.post<ResultList>(this.searchUrl, searchParams);

        res.subscribe(searchResult => {
            console.log("rates from remote ", searchResult)
            this.resultStore.dispatch(searchResult);
            // todo warum wird das hier dispatched?
            this.searchStore.dispatch({data: searchParams, type: SEARCH});

            return searchResult;
        });
        //return res;
    }

    getServiceOptions() {
    }


}
