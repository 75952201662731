import {Inject, Injectable} from "@angular/core";
import {ErrorMessage, ResultList} from "./result.model";
import {BehaviorSubject, Observable, Observer} from "rxjs";

import {HttpClient, HttpHeaders} from '@angular/common/http';
import * as uuid from "uuid";

/*todo not finished yet. Just another try to improve generic store*/
@Injectable()
export class ResultListsStore {
    url: string;
    data: ResultList = new ResultList();
    errors: ErrorMessage[] = [];
    changeFlag: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    errorFlag: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isLoading = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient, @Inject("searchRates") url) {
        this.url = url
    }

    public getData(): ResultList {
        return this.data;
    }

    public hasChanged(): Observable<boolean> {
        return this.changeFlag;
    }

    public hasError(): Observable<boolean> {
        return this.errorFlag;
    }

    public isDataLoading(): Observable<boolean> {
        return this.isLoading;
    }

    public setRateSearchContext(params: any) {
        let headers = new HttpHeaders();
        let tracingId = uuid.v4()
        headers.set("tracingId", tracingId)
        console.log("starte rates search with uuid " + tracingId);

        this.isLoading.next(true);
        this.http.post<ResultList>(this.url, params, {headers: headers}).subscribe({
            next: rl => this.dispatch(rl),
            error: er => {
                let backEndErr: ErrorMessage;
                backEndErr = er;
                this.errors.push(backEndErr);
            },
            complete: () => {
                this.isLoading.next(false);
                console.log("rate " + tracingId + " search request completed");
            }
        })
    }

    // todo prüfe ob der changeFlag überhaupt sinn macht (sieht gerade nicht danach aus.
// this method is just for assigning new data from backend and analysing if backend send any exceptions
    private dispatch(newData: ResultList) {
        this.data = newData;

        //So wie ich das verstehe brauchen wir diesen Flag um im search-detail-component eine entsprechende Meldung/ Toast zu
        // zeigen nach dem der Request vorbei war
        this.changeFlag.next(true);

        if (this.data.errors != null && this.data.errors.length > 0) {
            console.warn("found errors in response! Number of errors " + this.data.errors.length);
            this.errors = this.data.errors;
            this.errorFlag.next(true);
        }
    }
}

// this class defines filters for providing data
export class Filter {

}
